import { useState } from "react";
import axios from "axios";
import "./css/Store.css";
import nicepay_logo from "./img/nicepay.svg";

export const Store = () => {
  const [customer, setCustomer] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const link = "create_payment.php";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
  
    try {
      const response = await axios.post(link, {
        customer,
        email,
      });
  
      setLoading(false);
  
      if (response.data.success) {
        // Открываем ссылку на оплату в новой вкладке
        window.open(response.data.payment_link, "_blank");
      } else {
        setError(response.data.error || "Произошла неизвестная ошибка.");
      }
    } catch (err) {
      setLoading(false);
      setError(err.response?.data?.error || "Ошибка при отправке запроса.");
    }
  };

  return (
    <div id="store" className="content">
      <div className="container">
        <h1>Покупка привилегии</h1>
        <p>
          На этой странице вы можете немного поддержать сервер материально. Это
          нужно нам для того, чтобы поддерживать работу сервера с меньшими
          затратами. А вы, в свою очередь, получите небольшие плюшки на сервере.
        </p>

        <form onSubmit={handleSubmit}>
          <label>
            Никнейм
            <input
              type="text"
              value={customer}
              onChange={(e) => setCustomer(e.target.value)}
              placeholder="Введите никнейм"
              required
            />
          </label>
          <label>
            Электронная почта
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите адрес эл. почты"
              required
            />
            <span>* сюда будет направлен чек об операции</span>
          </label>
          <button type="submit" disabled={loading}>
            {loading ? "Обработка..." : "Перейти к оплате"}
          </button>
        </form>

        {error && <p style={{ color: "red" }}>{error}</p>}

        <div className="plus-tab">
          <div className="col">
            <div className="align">
              <h2>MINE <span>PLUS</span></h2>
              <span>399 рублей в месяц</span>
            </div>
          </div>
          <div className="col">
            <ul>
              <li><span>+</span> префикс в чате и команда /prefix</li>
              <li><span>+</span> кастомное сообщение о входе в игру</li>
              <li><span>+</span> доступ к /co i</li>
              <li><span>+</span> надеть предмет на голову: /hat</li>
              <li><span>+</span> наше уважение</li>
            </ul>
          </div>
        </div>

        <footer>
            <img src={nicepay_logo} alt="NicePay Logo"/>
            <span>Оплата производится с помощью NicePay</span>
        </footer>
      </div>
    </div>
  );
};
