import {useState, useEffect} from "react";
import axios from "axios";
import copyicon from "../img/copy-icon.svg";
import copyiconyes from "../img/copy-yes.svg";

const Players = ({ playerslist = [] }) => {
    useEffect(() => {
        playerslist = playerslist.slice(0,1);
    }, [playerslist]);
    return (
        <div class="players">
            {playerslist.slice(0,10).map(element => {
                return (<img src={`https://minotar.net/avatar/${element.name}`}/>)
            })}
        </div>
    );
}

export const Online = () => {
    const [loading, setLoading] = useState(true);
    const [online, setOnline] = useState();
    const [copied, setCopied] = useState(false);

    const address = "https://api.mcsrvstat.us/3/play.vanilliamine.ru";

    useEffect(() => {
        axios.get(address).then(function(response){
            setOnline(response.data);
            console.log(response.data);
        }).catch(function(error){
            console.log("Ошибка при получении онлайна: ", error);
        }).finally(function(){
            setLoading(false);
        });
    }, []);

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
      }
      function copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }

    const copy = (e) => {
        e.preventDefault();
        copyTextToClipboard("play.vanilliamine.ru");
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1500);
    }

    if(loading || !online){
        return (
            <div class="online">
                <div class="col">
                <h3><div class={"circle gray"}></div>Онлайн</h3>
                    <p>0<span>/0</span></p>
                </div>
                <div class="col">
                    <div class="ip">
                        <span>play.vanilliamine.ru</span>
                        <button onClick={copy}><img src={copied ? copyiconyes : copyicon}/></button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div class="online">
            <div class="col">
                <h3><div class={online.online ? "circle" : "circle off"}></div>Онлайн</h3>
                {online.online ? <p>{online.players.online}<span>/{online.players.max}</span></p> : <p>Offline</p>}
            </div>
            <div class="col">
                <div class="ip">
                    <span>play.vanilliamine.ru</span>
                    <button onClick={copy}><img src={copied ? copyiconyes : copyicon}/></button>
                </div>
            </div>
            {online.online ? <Players playerslist={online.players.list}/> : ""}
        </div>
    )
}