

export const Nav = ({ setTab, tab }) => {
    return (
        <nav>
            <div class="container">
                <a onClick={(e) => {setTab(0)}}>Vanillia<span>Mine</span></a>
                <ul>
                    <li className={tab === 0 ? "active" : ""}><a onClick={(e) => {e.preventDefault(); setTab(0);}}>Главная</a></li>
                    <li className={tab === 1 ? "active" : ""}><a onClick={(e) => {e.preventDefault(); setTab(1);}}>Донат</a></li>
                    <li className={tab === 2 ? "active" : ""}><a onClick={(e) => {e.preventDefault(); setTab(2);}}>Правила</a></li>
                    <li className={tab === 3 ? "active" : ""}><a onClick={(e) => {e.preventDefault(); setTab(3);}}>Контакты</a></li>
                </ul>
            </div>
        </nav>
    );
}