import {useState, useEffect} from "react"
import { Loading } from "./Loading";
import "./css/Main.css";
import { Online } from "./components/Online";

export const Main = ({ setTab = (tab) => {} }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.setTimeout(() => {
            setLoading(false);
        }, 500)
    }, []);

    if(loading){
        return (<Loading/>);
    }
    
    const click = (e) => {
        e.preventDefault();
        setTab(1);
    }

    return (
        <div id="main">
            <header className="head content">
                <div className="container">
                    <div className="col">
                        <div className="text">
                            <h1><span>Реальное</span> ванильное выживание в Minecraft 1.21.4</h1>
                            <p>Добро пожаловать в мир Vanillia Mine, здесь ты сможешь найти вайбовую атмосферу и приятное коммьюнити в ванильном майнкрафте новейшей версии</p>
                            <button type="button" onClick={click}>В магазин</button>
                        </div>
                        <Online/>
                    </div>
                </div>
            </header>
        </div>
    )
}