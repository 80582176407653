import "./css/Contacts.css";


export const Contacts = () => {
    return (
        <div id="contacts" className="content">
            <div className="container">
                <h1>Контакты</h1>
                <a href="https://t.me/nicdev4">Telegram</a>
                <a href="mailto:work@nicdev.space">work@nicdev.space</a>
                <a href="mailto:support@vanilliamine.ru">support@vanilliamine.ru</a>
            </div>
        </div>
    )
}