import './App.css';
import { Main } from './Main';
import { Nav } from './Nav';
import { useState, useEffect } from "react";
import { Store } from './Store';
import { Contacts } from "./Contacts";
import { Rules } from './Rules';

function App() {
  const [tab, setTab] = useState(0);

  return (
    <div className="App">
      <Nav setTab={setTab} tab={tab}/>
      {tab === 0 ? <Main setTab={setTab}/> : ""}
      {tab === 1 ? <Store/> : ""}
      {tab === 2 ? <Rules/> : ""}
      {tab === 3 ? <Contacts/> : ""}
    </div>
  );
}

export default App;
