import "./css/Rules.css"


export const Rules = () => {
    return (
        <div id="rules" className="content">
            <div className="container">
                <h1>Правила проекта</h1>
                <h2>1. Общие положения</h2>
                <p>1.1. Настоящие правила являются частью пользовательского соглашения. Начиная игру на нашем сервере, пользователь подтверждает, что ознакомплен с правилами проекта.</p>
                <p>1.2. Администрация проекта в праве менять правила на свое усмотрение без уведомления игроков.</p>
                <p>1.3. Гриферство - умышленное разрушение чужих построек, убийство игрока с целью получить материальную выгоду (в игре), в том числе с использованием обмана, ловушек и т.п.</p>
                <p>1.4. Администрацией проекта признаются все игроки, которые имеют роль Модератор и выше.</p>
                <p>1.5. Командой проекта признаются все игроки проекта, которые имеют роль Хелпер.</p>
                <p>1.6. Донат - внутриигровая привилегия (роль), которая выдается за пожертвование определенной суммы на определенный срок. Срок устанавливается Админитрацией проекта.</p>
                <p>1.7. Администрация и команда проекта вправе выбирать срок наказаний на свое усмотрение.</p>
                <h2>2. Чат, общение</h2>
                <p>2.1. Запрещены любые оскорбления других игроков, в том числе в завуалированной форме.</p>
                <p>2.2. Запрещено оскорбление проекта.</p>
                <p>2.3. Запрещена реклама сторонних продуктов, не связанных с данным проектом, в том числе других серверов Minecraft.</p>
                <p>2.4. Запрещены высказывания, нарушающие законодательство Российской Федерации и (или) Республики Беларусь.</p>
                <p>2.4.1. Запрещен призыв к дейстиям, запрещенным законодательством Российской Федерации и (или) Республики Беларусь.</p>
                <p>2.5. Запрещено разведение политических, религиозных, межнациональных конфликтов, в том числе запрещены высказывания на подобные темы.</p>
                <p>2.6. Запрещена клевета в отношении других игроков.</p>
                <p>2.6.1. Запрещен обман администрации.</p>
                <h2>3. Игровой процесс</h2>
                <p>3.1. Запрещено гриферство игроков.</p>
                <p>3.2. Территорией игрока признается место его постоянной дизлокации (Например база, дом, склад, фармилка, иное), другие постройки игрока и территория в радиусе 50 блоков от нее.</p>
                <p>3.2.1. Запрещено разрушать блоки на территории игрока, если владелец территории против.</p>
                <p>3.2.2. Запрещено находиться на территории игрока с целью провокации, или создавая тем самым помеху игре.</p>
                <p>3.3. Запрещено строить механизмы (иные постройки), цель которых навредить серверу или нарушить его работоспособность.</p>
                <p>3.3.1. Администрация вправе временно или перманентно отключить механизмы, которые создают высокую нагрузку или несут в себе другую угрозу проекту или его игрокам.</p>
                <p>3.4. Если игрок находится оффлайн более двух недель (14 дней), то территория, описанная в п. 3.2., более не пренадлежит ему. Исключение: Администрация, Команда Проекта</p>
                <p>3.5. Запрещены постройки, которые каким-либо образом содержат в себе признаки нацизма, фашизма, имеют эротический характер, или содержат в себе информацию, описанную в п. 2.4. Администрация (Команда проекта) вправе полностью восстановить регион с постройками без предупреждения.</p>
                <h2>4. Правила рейдов</h2>
                <p>4.1. Рейд - нападение на территорию другого игрока, при котором вам разрешено гриферство на его территории.</p>
                <p>4.2. Минимальный порог для совершения рейда - 2 и более игрока со стороны нападающих и равное количество защищающихся.</p>
                <p>4.3. Для совершения рейда вам необходимо уведомить администрацию (команду проекта) в момент совершения рейда.</p>
                <p>4.4. Запрещено рейдить игроков, которые играют на проекте менее двух недель. За уточнением информации о времени игры необходимо обратиться к администратору.</p>
                <p>4.5. В рейде запрещено использовать элитры, золотые зачарованные яблоки, кристаллы края.</p>
                <p>4.6. Любая из сторон вправе сдаться во время рейда. Для этого игрок, начавший рейд, должен обратиться к администрации проекта.</p>
                <p>4.7. Стороне, победившей во время рейда, достаются все ресурсы, добытые во время рейда.</p>
                <p>4.8. Во время рейда запрещено чрезмерное разрушение блоков территории защищающейся стороны со стороны нападающих. Администрация вправе запретить разрушать блоки, если установит факт злоупотребления данным разрешением.</p>
                <p>4.9. Если одна из сторон полностью покидает территорию рейда (территорию защищающихся), то она признает свое поражение.</p>
                <p>4.10. Злоупотребление правилами рейдов, а также использование недоработок в правилах рейдов, может наказываться на усмотрение администрации: от поражения до блокировки аккаунта.</p>
                <h2>5. Администрация</h2>
                <p>5.1. Администрация вправе наказывать игроков на свое усмотрение, в том числе в случаях, не описанных в настоящих правилах.</p>
                <p>5.2. Администрация вправе ограничить игроку доступ к игровой платформе по личной инициативе без указания причин и (или) уведомления.</p>
            </div>
        </div>
    )
}